import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { animated, useTransition } from 'react-spring';
import { closeCheckout } from '../../pages/onepage';
import { closeCheckoutDelete } from '../../pages/onepage';
import { directionStyles } from './styles';
/**
 * Constast
 */
import { STEP } from './constants';

/**
 * Steps
 */
import Methods from './steps/methods';
import CreditCard from './steps/creditCard';
import Facturation from './steps/facturation';
import ValidateRandomDiscount from './steps/ValidateRandomDiscount';
import Sms from './steps/sms';
import Response from './steps/response';
import ResponseDomiciliation from './steps/response/ResponseDomiciliation';
import AdminSubscription from './steps/adminSubscription';
import ShadowScrollbars from '../customScroll/ShadowScrollbars';
import Validatecode from '../validateCode';
import Subscriptions from './steps/adminSubscription/subscriptions';
import Payment from './steps/adminSubscription/payment';
import History from './steps/adminSubscription/history';
import FacturacionDebito from './steps/debito/FacturacionDebito';
import CuentaDebito from './steps/debito/CuentaDebito';
import Mediospago from '../MediosPago';
import DefineCardsDues from './steps/defineCardsDues';

/**
 * Global state
 */
import useGlobal from '../../config/global';
import { getResponseFinish } from '../../util/helpers/response';

function Step({ idTransaction, transaction }) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();
  const [isAnimating] = useState(false);
  const [entroCC, setEntro] = useState(false);

  /**
   * Set current step
   */
  const CurrentStep = useMemo(
    () => globalState.step,
    [globalState.step]
  );
  // const CurrentStep = STEP.debito;

  const MainbuttonAction = (show, text, action, loading) => {
    globalActions.setMainButton({
      onClick: action,
      text,
      show,
      loading,
    });
  };

  /**
   * User refs
   */
  const tdcRef = useRef(null);
  const factRef = useRef(null);
  const validateRandomDiscountRef = useRef(null);
  const respRef = useRef(null);
  const smsRef = useRef(null);
  const resDomRef = useRef(null);
  const scrollRef = useRef(null);
  const verifyCode = useRef(null);

  /**
   * Set button
   */

  const SetReferencias = () => {
    if (CurrentStep === STEP.creditCard) {
      MainbuttonAction(true, 'Continuar', () =>
        tdcRef.current.dispatchEvent(new Event('submit'))
      );
    }

    if (CurrentStep === STEP.facturation) {
      MainbuttonAction(true, globalState.action, () =>
        factRef.current.dispatchEvent(new Event('submit'))
      );
    }
    if (CurrentStep === STEP.validateRandomDiscount) {
      MainbuttonAction(true, 'Continuar', () =>
        validateRandomDiscountRef.current.dispatchEvent(
          new Event('submit')
        )
      );
    }
    if (CurrentStep === STEP.sms) {
      MainbuttonAction(false, 'Continuar', () => null);
    }

    if (CurrentStep === STEP.response) {
      MainbuttonAction(false, 'Continuar', () => null);
    }

    if (CurrentStep === STEP.methods) {
      MainbuttonAction(false, 'Continuar', () => null);
    }

    if (CurrentStep === STEP.login) {
      MainbuttonAction(false, 'Continuar', () => null);
    }

    if (CurrentStep === STEP.responseDomiciliation) {
      MainbuttonAction(
        true,
        globalState.isAdmin || globalState.isMultipleSubscriptions
          ? 'Finalizar'
          : globalState.dataResponse !== null
          ? globalState.dataResponse.status
            ? 'Finalizar'
            : 'Reintentar'
          : 'Reintentar',

        globalState.isAdmin
          ? () => {
              globalActions.setStep(STEP.admin);
              globalActions.setDirection(true);
            }
          : globalState.dataResponse !== null
          ? globalState.dataResponse.status ||
            globalState.isMultipleSubscriptions
            ? () => {
                if (globalState) {
                  closeCheckout(getResponseFinish(globalState));
                }
              }
            : () => {
                globalActions.setStep('methods');
                globalActions.setDirection(true);
              }
          : () => {
              globalActions.setStep('methods');
              globalActions.setDirection(true);
            }
      );
    }

    if (CurrentStep === STEP.validateCode) {
      MainbuttonAction(true, 'Continuar', () =>
        verifyCode.current.dispatchEvent(
          new Event('submit', { cancelable: true })
        )
      );
    }

    if (CurrentStep === STEP.admin) {
      MainbuttonAction(false, 'Continuar', () => null);
    }

    if (CurrentStep === STEP.admin) {
      MainbuttonAction(false, 'Continuar', () => null);
    }

    if (CurrentStep === STEP.directionStyles) {
      MainbuttonAction(false, 'Guardar', () => null);
    }
  };

  const [shouldSetAnimating, setShouldSetAnimating] = useState(false);

  const transitions = useTransition(CurrentStep, (item) => item, {
    config: { mass: 1, tension: 350, friction: 29 },
    from: globalState.leftDirection
      ? directionStyles.left.from
      : directionStyles.right.from,
    enter: {
      opacity: 1,
      transform: 'translate3d(0,0,0)',
      width: '100%',
      position: 'relative',
    },
    leave: globalState.leftDirection
      ? directionStyles.left.leave
      : directionStyles.right.leave,
    native: true,
    onStart:
      !entroCC && !globalState.isAnimating
        ? () => {
            setShouldSetAnimating(true);
            //globalActions.setAnimating(true);
            setEntro(true);
          }
        : null,
    onRest:
      entroCC && globalState.isAnimating
        ? () => {
            setShouldSetAnimating(false);
            //globalActions.setAnimating(false);
          }
        : null,
  });

  useEffect(() => {
    if (shouldSetAnimating) {
      globalActions.setAnimating(true);
    } else if (!shouldSetAnimating && globalState.isAnimating) {
      globalActions.setAnimating(false);
    }
  }, [shouldSetAnimating, globalState.isAnimating, globalActions]);

  const STEPS_SCREN = useMemo(
    () =>
      Object.assign(
        {},
        {
          validateCode: () => (
            <Validatecode
              idTransaction={idTransaction}
              transaction={transaction}
              Ref={verifyCode}
            />
          ),
          login: () => <Methods transaction={transaction} />,
          TDC: () => (
            <CreditCard
              TDCref={tdcRef}
              idTransaction={idTransaction}
              entroCC={entroCC}
              controlScroll={() =>
                scrollRef.current.scrollToBottom('0')
              }
            />
          ),
          facturation: () => (
            <Facturation
              factRef={factRef}
              showBtn={!isAnimating}
              idTransaction={idTransaction}
            />
          ),
          validateRandomDiscount: () => (
            <ValidateRandomDiscount
              validateRandomDiscountRef={validateRandomDiscountRef}
            />
          ),
          sms: () => (
            <Sms smsRef={smsRef} idTransaction={idTransaction} />
          ),
          methods: () => <Mediospago />,
          response: () => (
            <Response
              respRef={respRef}
              showBtn={!isAnimating}
              idTransaction={idTransaction}
            />
          ),
          domiciliacionresponse: () => (
            <ResponseDomiciliation
              respRef={respRef}
              showBtn={!isAnimating}
              idTransaction={idTransaction}
              resDomRef={resDomRef}
              closeCheckout={closeCheckout}
            />
          ),
          adminSubscription: () => <AdminSubscription />,
          adminPayment: () => <Payment />,
          adminSuscription: () => (
            <Subscriptions closeCheckout={closeCheckoutDelete} />
          ),
          adminHistoryPay: () => <History />,
          defineDuesCards: () => <DefineCardsDues />,
          debito: () => <FacturacionDebito />,
          debitoAcount: () => <CuentaDebito />,
        }
      ),
    [globalActions.step]
  );

  useEffect(() => {
    SetReferencias();

    globalActions.lockDrag(false);

    // SUBE EL SCROLL CUANDO CAMBIE EL STEP
    if (scrollRef.current) {
      scrollRef.current.scrollTop(0);
    }

    if (CurrentStep === STEP.login) {
      globalActions.setMiga([STEP.login]);
    }
    // QUITA LAS MIGAS DE PAN
    else if (
      CurrentStep === (STEP.response || STEP.responseDomiciliation)
    ) {
      let filtrado = [...globalState.migas].filter(
        (item) =>
          item !== (STEP.creditCard || STEP.validateCode || STEP.sms)
      );
      globalActions.setMiga(filtrado);
    } else {
      // ELIMINA LOS STEPS QUE NO SE DEBEN GUARDAR
      let realMigas = [...globalState.migas, CurrentStep].filter(
        (item) => item !== ('validateCode' || 'sms')
      );
      globalActions.setMiga(realMigas);
    }

    // RESETEA LA DIRECCIONES DE LAS TRANSICIONES
    if (globalState.leftDirection) {
      globalActions.setDirection(false);
    }

    if (entroCC && CurrentStep !== STEP.login) {
      setEntro(false);
    }
  }, [CurrentStep]);

  return (
    <ShadowScrollbars
      className={'contScroll'}
      style={{
        width: '100%',
        background: 'white',
      }}
      theref={scrollRef}
    >
      {transitions.map(({ item, props, key }) => {
        let M = STEPS_SCREN[item];
        return (
          <animated.div
            style={{ zIndex: 1, oveflow: 'hidden', ...props }}
            key={key}
          >
            <M />
          </animated.div>
        );
      })}
    </ShadowScrollbars>
  );
}

Step.propTypes = {
  step: PropTypes.string.isRequired,
  idTransaction: PropTypes.string,
  transaction: PropTypes.object,
};

Step.defaultProps = {
  step: STEP.methods,
  transaction: {},
};

export default React.memo(Step);
