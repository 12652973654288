import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import valid from 'card-validator';

/**
 * Mutation
 */
import { CREDIT_CARD } from '../../../../data/mutations/payment';

/**
 * Global state
 */
import useGlobal from '../../../../config/global';

/**
 * Constast
 */
import { STEP } from '../../constants';
import Flex from '../../../Flex';
import Titlesection from '../../../TitleSection';
import Input from '../../../input';
import Alertphone from '../../../AlertPhone';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { WrapperStep } from '../styles';
import { SOLOLETRAS, SOLONUMEROS } from '../../../../util/rex';
import { GetFranchise } from '../../../helper';
import consultaBin from '../../../UI/BinIconCard/consultaBin';
import { getCountries } from '../../../helpers/getCountries';

const TDC_schema = Yup.object().shape({
  cardNumber: Yup.string()
    .matches(
      /(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})/,
      'Número no válido'
    )
    .min(13, 'Número no válido.')
    .max(19, 'Número no válido.')
    .required('Debe ingresar un número de tarjeta.'),
  cardName: Yup.string()
    .matches(/(\w.+\s).+/, 'Nombre en tarjeta no válido.')
    .required('Debe ingresar un nombre y apellido.'),
  cvv: Yup.string()
    .min(3, 'cvv no válido.')
    .max(4, 'cvv no válido.')
    .required('Debe ingresar un código cvv.'),
  cardDate: Yup.string()
    .test(
      'test-expiration',
      'Fecha no válida.',
      (value) => valid.expirationDate(value).isValid
    )
    .max(5, 'Fecha no válida.')
    .required('Debe ingresar una fecha de expiración.'),
  cardCuotas: Yup.array()
    .compact(function (v) {
      return (
        Object.entries(v).length === 0 && v.constructor === Object
      );
    })
    .required('Debe selecionar número de cuotas.'),
  indicative: Yup.string()
    .min(3, 'Indicativo no válido.')
    .required('Debe seleccionar un indicativo o país.'),
  phoneNumber: Yup.string()
    .min(10, 'Número no válido.')
    .required('Debe ingresar un número de teléfono móvil.'),
});

function CreditCard(props) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();
  const [nextStep, setNextStep] = useState(null);

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dues, setDues] = useState([{ value: 12 }]);
  const [phone, setPhone] = useState('');
  const [indicative, setIndicative] = useState('');
  const [token, setToken] = useState(null);
  const [cardsDomiciliation, setCardsDomiciliation] = useState([]);
  /*
 SE QUITA HASTA DEFINIR USO DEL BOTON
 const [save, setSave] = useState(false); */
  const [paises, setPaises] = useState([]);
  const [franchise, setFran] = useState('tdc');
  const [cardNumber, setCardNum] = useState('');

  //get id transaction
  const { id } = useParams();

  //Mutation hook
  const [createToken, { loading }] = useMutation(CREDIT_CARD, {
    onError: (error) => {
      console.log('ERROR :::::::', error);
      globalActions.setErrorAlert({
        icon: 'error',
        title: '¡Error!',
        message: [
          `Ocurrió un error al tokenizar o guardar el medio de pago.`,
        ],
        content: false,
        hideButtons: false,
        btnText: 'Aceptar',
        btnCallback: () => {
          globalActions.setStep('methods');
          globalActions.setDirection(true);
        },
      });
      globalActions.setMainButton({
        ...globalState.mainButton,
        loading: false,
      });
    },

    onCompleted: ({ creditCard }) => {
      globalActions.setErrorAlert({
        title: '',
        icon: '',
        message: [],
        content: 'false',
        hideButtons: false,
      });

      globalActions.setMainButton({
        ...globalState.mainButton,
        loading: false,
      });

      if (!creditCard.status || creditCard.error) {
        globalActions.setErrorAlert({
          icon: 'error',
          message: [
            creditCard.message ||
              'Ocurrió un error al intentar guardar el medio de pago, por favor verifique los datos e intente nuevamente.',
          ],
          title: '¡Algo salió mal!',
          content: false,
          btnText: 'Aceptar',
          btnCallback: () => {
            globalActions.setStep('methods');
            globalActions.setDirection(true);
          },
        });
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.group('Data token step creditCard');
          console.log(
            `return status with token: ${creditCard.payment.token}`
          );
          console.groupEnd();
        }
        setToken(creditCard.payment.token);
        setCardsDomiciliation([
          { token: creditCard.payment.token, dues },
        ]);
        globalActions.setToken(creditCard.payment.token);
        setNextStep(STEP.facturation);
      }
    },
  });

  useEffect(() => {
    if (nextStep) {
      globalActions.setDataForm({
        ...globalState.dataForm,
        dues,
        token,
        name,
        lastName,
        indicative,
        phone,
        /*
        SE QUITA HASTA DEFINIR USO DEL BOTON
        save, */
        cardsDomiciliation,
      });
      globalActions.setStep(nextStep);
    }
  }, [nextStep]);

  useEffect(() => {
    if (loading) {
      globalActions.setLoading(true);
    } else {
      globalActions.setLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    getCountries((x) => setPaises(x.indicativos));
    globalActions.updateState({
      migasUp: {
        show: true,
        icon: true,
        iconName: 'credit_card',
      },
    });
  }, []);

  useEffect(() => {
    if (cardNumber.length > 6 && franchise === 'tdc') {
      consultaBin(cardNumber.substring(0, 6), (x) => setFran(x));
    } else if (cardNumber.length < 6) {
      setFran('tdc');
    }
  }, [cardNumber]);

  let cuotas = [];
  for (let i = 1; i < 37; i++) {
    cuotas.push({ label: i, value: i });
  }

  let CVVInfo = styled.div`
    .title {
      font-weight: bold;
      text-align: center;
      font-size: 18px;
      margin-bottom: 1em;
      width: 100%;
    }
  `;

  let cvcInfo = () => {
    return (
      <CVVInfo className={'wc text-center py-3'}>
        <div className="title">¿Qué es el CVV?</div>
        <p>
          Es un código de seguridad de 3 ó 4 dígitos encontrados en la
          parte de atrás de su tarjeta
        </p>
        <img
          src="/img/cvvImg.png"
          alt=""
          style={{ height: '50px', width: 'auto', display: 'auto' }}
        />
      </CVVInfo>
    );
  };

  let tooltipCVC = () => {
    return (
      <Flex
        direction={'column'}
        className={'hc'}
        style={{ color: '#2196F3', width: '30px', cursor: 'pointer' }}
      >
        <i
          className="material-icons"
          onClick={() =>
            globalActions.setErrorAlert({
              message: [() => cvcInfo],
              content: () => cvcInfo(),
            })
          }
        >
          info
        </i>
      </Flex>
    );
  };

  return (
    <WrapperStep
      className="methods wc"
      isAnimating={globalState.isAnimating}
    >
      <Formik
        initialValues={{
          cardNumber: '',
          cardName: '',
          cvv: '',
          cardDate: '',
          cardCuotas: '',
          indicative: [
            {
              name: 'Colombia',
              value: '+57',
            },
          ],
          phoneNumber: '',
          save: false,
        }}
        validationSchema={TDC_schema}
        onSubmit={(values) => {
          let seleccionada = [
            {
              franchise: GetFranchise(values.cardNumber),
              mask: values.cardNumber.toString().trim(),
              dues: values.cardCuotas[0].value,
            },
          ];

          globalActions.updateState({
            cardsSelectedForsuscribe: seleccionada,
          });

          let cardName = values.cardName.trim().replace(/\s+/g, ' ');
          let firstName = cardName.split(' ')[0];
          let last = cardName.split(' ');
          last = last[last.length - 1];

          setName(firstName);
          setLastName(last);
          setDues(values.cardCuotas[0].value);
          setPhone(values.phoneNumber.toString().trim());
          setIndicative(values.indicative[0].value);
          /*
          Se quita hasta definir uso del boton de guardar
          setSave(values.save); */
          globalActions.setMainButton({
            ...globalState.mainButton,
            loading: true,
          });

          globalActions.setErrorAlert({
            title: 'Validando tarjeta',
            icon: 'info',
            message: [
              ['mastercard', 'visa'].includes(franchise)
                ? 'En este momento estamos validando el estado de su tarjeta con la entidad bancaria'
                : 'Se realizará un cobro aleatorio entre $100 COP y $500 COP para validar su tarjeta',
            ],
            content: false,
            btns: [],
            hideButtons: true,
          });
          createToken({
            variables: {
              form: {
                numberCard: values.cardNumber.toString().trim(),
                expiration: values.cardDate,
                code: values.cvv,
                dues: values.cardCuotas[0].value,
                indicative: values.indicative[0].value,
                phoneNumber: values.phoneNumber,
                cardName: cardName,
                email: globalState.email,
                ip: '127.0.0.1',
              },
              type: globalState.type,
              idTransaction: id,
            },
          });
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form
            ref={props.TDCref}
            style={{
              position: 'relative',
              zIndex: '0',
              willChange: 'transform',
              transform: 'translateZ(0)',
              overflow: 'hidden',
            }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
              if (
                (errors.phoneNumber || errors.indicative) &&
                !errors.cardNumber
              ) {
                props.controlScroll();
              }
            }}
          >
            <div className="wc px-3 px-sm-4 pb-2">
              <div className="wc">
                <Titlesection
                  text={'Ingrese los datos de su tarjeta'}
                />
              </div>

              <Input
                tipo={'tdc'}
                type={'text'}
                onChange={(e) => {
                  setCardNum(e.value);
                  setFieldValue(
                    'cardNumber',
                    e.value.replace(' ', '')
                  );
                }}
                name={'cardNumber'}
                value={values.cardNumber}
                label={'Número de la tarjeta'}
                flex={'1 0 100%'}
                className={'mb-3'}
                icon={'credit_card'}
                iconCard={franchise}
                disabled={globalState.loading}
                error={
                  errors.cardNumber &&
                  touched.cardNumber &&
                  errors.cardNumber
                }
              />

              <Flex
                className="wc mb-3"
                flex={'1 0 100%'}
                alg={'flex-start'}
                jc={'flex-start'}
              >
                <Input
                  tipo="date"
                  type={'text'}
                  className="col-7 px-0"
                  onChange={handleChange}
                  value={values.cardDate}
                  label="Fecha de vencimiento"
                  flex={'1 0 150px'}
                  name={'cardDate'}
                  icon={'date_range'}
                  disabled={globalState.loading}
                  error={
                    errors.cardDate &&
                    touched.cardDate &&
                    errors.cardDate
                  }
                />

                <Input
                  tipo="password"
                  type={'password'}
                  onChange={(x) => {
                    if (SOLONUMEROS.test(x.target.value)) {
                      let value = x.target.value;
                      if (value.length > 4)
                        value = value.substring(0, 4);
                      setFieldValue('cvv', value);
                    }
                  }}
                  value={values.cvv}
                  label={'CVV'}
                  flex={'1 0 120px'}
                  className={'col-5 px-0 pl-4'}
                  name={'cvv'}
                  icon={'https'}
                  disabled={globalState.loading}
                  iconAfter={tooltipCVC}
                  error={errors.cvv && touched.cvv && errors.cvv}
                />
              </Flex>
              <Flex
                className="wc mb-3"
                flex={'1 0 100%'}
                alg={'flex-start'}
              >
                <Input
                  className="col-7 px-0"
                  tipo="text"
                  onChange={(e) => {
                    if (SOLOLETRAS.test(e.target.value)) {
                      handleChange(e);
                    }
                  }}
                  value={values.cardName}
                  name={'cardName'}
                  icon={'perm_identity'}
                  disabled={globalState.loading}
                  label="Propietario de la tarjeta"
                  error={
                    errors.cardName &&
                    touched.cardName &&
                    errors.cardName
                  }
                />

                <Input
                  tipo="select"
                  type={'text'}
                  onChange={handleChange}
                  className={'col-5 px-0 pl-4'}
                  options={cuotas}
                  value={values.cardCuotas}
                  name={'cardCuotas'}
                  label="Cuotas"
                  labelOption={'label'}
                  valueOption={'value'}
                  searchable={false}
                  customLabel={'label'}
                  icon={'sort'}
                  disabled={globalState.loading}
                  error={
                    errors.cardCuotas &&
                    touched.cardCuotas &&
                    errors.cardCuotas
                  }
                />
              </Flex>
            </div>
            {/*
  SE QUITA HASTA DEFINIR USO DEL BOTON
            <div className={'wc px-3 px-sm-4 mb-2'}>
              <Rememberme
                handle={() => setFieldValue('save', !values.save)}
                color={globalState.color}
                value={values.save}
                disabled={globalState.loading}
                name={'save'}
                texto={'Guardar tarjeta para futuras transacciones'}
              />
            </div> */}
            <div className="wc p-3 px-sm-4">
              <Alertphone
                indicativoValue={values.indicative}
                phoneValue={values.phoneNumber}
                onChangePhone={handleChange}
                onchangeIndicativo={handleChange}
                phonePlaceholder={'Teléfono'}
                options={paises}
                disabled={globalState.loading}
                error={{
                  indicative:
                    errors.indicative &&
                    touched.indicative &&
                    errors.indicative,
                  number:
                    errors.phoneNumber &&
                    touched.phoneNumber &&
                    errors.phoneNumber,
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </WrapperStep>
  );
}

CreditCard.propTypes = {};

CreditCard.defaultProps = {};

export default React.memo(CreditCard);
