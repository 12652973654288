import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Input as InputBase } from 'baseui/input';
import { Select } from 'baseui/select';
import { useStyletron } from 'baseui';
import { Collapse } from 'react-collapse';
import { ContInput } from './styles/index';
import { MaskedInput } from 'baseui/input';
import { isString } from 'formik';
import useGlobal from '../../config/global';
import { PaymentCard } from 'baseui/payment-card';
import { Before, ShowError } from './helpers';
import Check from './checkbox';
import BinIconCard from '../UI/BinIconCard/BinIConCard';
import Flex from '../Flex';
import NumberFormat from 'react-number-format';
import { accountTypes, docTypes } from './constants';
import { Tooltip } from 'react-tippy';

let ValidaColorBorde = (focus, error, color) => {
  return focus ? (error ? 'red' : color) : error ? 'red' : '#e4e4e4';
};

const TooltipComponent = ({ config }) => (
  <Tooltip
    tabIndex={4}
    title={config.title}
    position={config.position}
    style={{
      color: '#049BF3',
      position: 'absolute',
      right: '10px',
      bottom: '5px',
      zIndex: '3',
    }}
  >
    <i className={'material-icons'} style={{ fontSize: '25px' }}>
      {config.icon}
    </i>
  </Tooltip>
);

const InputTextNumber = (props) => {
  let After = props.iconAfter;

  const [labelUp, setLabel] = useState(false);
  const [globalState] = useGlobal();
  const isMounted = useRef(true);

  let ColorTema = '#' + globalState.commerce.colorLogo;

  useEffect(() => {
    isMounted.current = true;
    if (props.value && isMounted.current) {
      setLabel(true);
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleBlur = () => {
    if (!isMounted.current) return;

    if (props.value !== undefined) {
      if (props.value !== '' && props.value.length !== 0) {
        setLabel(true);
      } else {
        setLabel(false);
      }
    } else {
      setLabel(false);
    }
  };

  const handleFocus = (e) => {
    if (!isMounted.current) return;

    e.target.addEventListener(
      'wheel',
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
    setLabel(true);
  };

  return (
    <ContInput
      labelUp={labelUp}
      leftSpace={
        (props.icon || props.customIcon) !== undefined ? '40px' : '0'
      }
      haslabel={props.label !== undefined}
      className={props.className}
      flex={props.flex}
    >
      <div className="wc position-relative">
        {props.label && <label>{props.label}</label>}
        {props.tooltip && <TooltipComponent config={props.tooltip} />}
        <InputBase
          max={props.max}
          overrides={{
            InputContainer: {
              style: ({ $isFocused, $disabled, $error }) => {
                return {
                  borderWidth: '0',
                  height: '40px',
                  borderBottom: `2px solid ${ValidaColorBorde(
                    $isFocused,
                    $error,
                    ColorTema
                  )}`,
                  borderColor: ValidaColorBorde(
                    $isFocused,
                    $error,
                    ColorTema
                  ),
                  position: 'relative',
                  zIndex: '2',
                  marginBottom: '0',
                  backgroundColor: 'rgba(0,0,0,0)',
                  color: props.disabled
                    ? 'rgb(175, 175, 175)'
                    : 'black',
                  cursor: props.disabled ? 'not-allowed' : 'pointer',
                };
              },
            },
            Input: {
              style: () => {
                return {
                  paddingRight: '0',
                  backgroundColor: 'rgba(0,0,0,0)',
                  color: props.disabled
                    ? 'rgb(175, 175, 175)'
                    : 'black',
                };
              },
            },
            Before:
              props.icon || props.customIcon
                ? () => (
                    <Before
                      disabled={props.disabled}
                      error={props.error}
                      customColor={props.customColor}
                      customIcon={props.customIcon}
                      icon={props.icon}
                    />
                  )
                : null,

            //props.icon || props.customIcon ? Before : null,
            After: props.iconAfter ? After : null,
          }}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          name={props.name}
          inputRef={props.REF}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={typeof props.error == 'string'}
          getValueLabel={({ option }) => option.value}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          onKeyDown={
            props.type == 'number'
              ? (evt) =>
                  [
                    'e',
                    'E',
                    '+',
                    '-',
                    'ArrowDown',
                    'ArrowUp',
                  ].includes(evt.key) && evt.preventDefault()
              : null
          }
          style={
            props.type == 'number'
              ? {
                  appearance: 'textfield',
                  MozAppearance: 'textfield',
                  WebkitAppearance: 'textfield',
                }
              : null
          }
        />
        <ShowError error={props.error} />
      </div>
    </ContInput>
  );
};

const InTDC = (props) => {
  const [labelUp, setLabel] = useState(false);
  const [globalState] = useGlobal();

  let colort = globalState.commerce.colorLogo;

  useEffect(() => {
    if (props.value) {
      setLabel(true);
    }
  }, []);

  return (
    <ContInput
      labelUp={labelUp}
      leftSpace={
        (props.icon || props.customIcon) !== undefined ? '40px' : '0'
      }
      haslabel={props.label !== undefined}
      className={props.className}
      flex={props.flex}
      color={props.error ? 'red' : '#e4e4e4'}
      colorFocus={props.error ? 'red' : '#' + colort}
    >
      <div className="wc position-relative">
        {props.label && <label htmlFor="">{props.label}</label>}

        <Flex className={'wc position-relative'}>
          <Flex
            direction={'column'}
            flex={'0 0 30px'}
            className={'icon-tdc'}
          >
            <img
              src={`/img/cards/${props.iconCard}.png`}
              alt=""
              width={'30px'}
              height={'auto'}
              className={'mx-auto'}
            />
          </Flex>
          <NumberFormat
            format="#### #### #### ####"
            max={props.max}
            value={props.value}
            onValueChange={props.onChange}
            name={props.name}
            disabled={props.disabled}
            style={{ flex: '1 0 70%' }}
            className={'formatNumber'}
            onFocus={() => setLabel(true)}
            onBlur={() => {
              if (props.value) return;
              setLabel(false);
            }}
          />
        </Flex>
        <ShowError error={props.error} />
      </div>
    </ContInput>
  );
};

const InputTDC = React.memo(InTDC);

const SelectInput = (props) => {
  const [globalState] = useGlobal();
  const [labelUp, setLabel] = useState(false);

  let defaultOptions = {
    accountTypes,
    docTypes,
  };

  let { options } = props;
  if (typeof options === 'string') {
    options = defaultOptions[options];
  }

  let colorTheme = '#' + globalState.commerce.colorLogo;

  useEffect(() => {
    props.value !== undefined &&
      props.value !== '' &&
      props.value.length !== 0 &&
      setLabel(true);
  }, []);

  return (
    <ContInput
      labelUp={labelUp}
      leftSpace={
        (props.icon || props.customIcon) !== undefined ? '40px' : '0'
      }
      haslabel={props.label !== undefined}
      className={props.className}
      flex={props.flex}
      mw={props.mw}
      labelSelect={true}
    >
      <div className="wc position-relative">
        {props.label && <label htmlFor="">{props.label}</label>}
        {(props.icon || props.customIcon) && (
          <Before
            icon={props.icon}
            customIcon={props.customIcon}
            customColor={props.customColor}
            error={props.error}
            disabled={props.disabled}
            className={'icon'}
          />
        )}
        <Select
          disabled={props.disabled}
          onChange={(e) => {
            e.target = {
              value: [Object.assign({}, e.option)],
              name: props.name,
            };
            props.onChange(e);
          }}
          searchable={props.searchable}
          name={props.name}
          error={props.error}
          value={props.value}
          options={options}
          labelKey={props.labelOption}
          valueKey={props.valueOption}
          overrides={{
            Root: {
              style: () => {
                return {
                  position: 'relative',
                  zIndex: '3',
                };
              },
            },
            ControlContainer: {
              style: ({ $isFocused, $error, $disabled }) => {
                return {
                  backgroundColor: 'transparent',
                  borderWidth: 0,
                  borderBottom: `2px solid ${ValidaColorBorde(
                    $isFocused,
                    $error,
                    colorTheme
                  )}`,
                  borderColor: ValidaColorBorde(
                    $isFocused,
                    $error,
                    colorTheme
                  ),
                  paddingLeft: '20px',
                  height: '40px',
                  maxWidth: '100%',
                  zIndex: '3',
                };
              },
            },
            Popover: {
              props: {
                overrides: {
                  Body: {
                    style: {
                      marginTop: '0',
                      marginBottom: '0',
                      zIndex: '9999',
                    },
                  },
                },
              },
            },
            Dropdown: {
              style: () => {
                return {
                  maxHeight: '50vh',
                };
              },
            },
          }}
          onBlur={() => {
            if (props.value instanceof Array) {
              setLabel(!!Object.entries(props.value)[0]);
            }
          }}
          onFocus={() => setLabel(true)}
          placeholder={''}
          clearable={false}
          getValueLabel={({ option }) =>
            props.customLabel
              ? option[props.customLabel]
              : option.label
          }
        />
        <ShowError error={props.error} />
      </div>
    </ContInput>
  );
};

const DateInput = (props) => {
  const [globalState] = useGlobal();
  const [labelUp, setLabel] = useState(false);

  function Before() {
    const [css] = useStyletron();
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          fontZise: '15px',
          width: '30px',
          paddingRight: '8px',
          color: props.error
            ? 'red'
            : props.disabled
            ? '#e4e4e4'
            : globalState.commerce.colorLogo !== undefined
            ? '#' + globalState.commerce.colorLogo
            : '#323232',
        })}
      >
        <i className={'material-icons'}>{props.icon}</i>
      </div>
    );
  }

  //
  useEffect(() => {
    props.value !== '' && setLabel(true);
  }, []);

  return (
    <ContInput
      labelUp={labelUp}
      leftSpace={
        (props.icon || props.customIcon) !== undefined ? '40px' : '0'
      }
      haslabel={props.label !== undefined}
      flex={props.flex}
      className={props.className}
    >
      <div className="position-relative wc ">
        {props.label && (
          <label htmlFor="">{labelUp && props.label}</label>
        )}

        <MaskedInput
          error={props.error}
          value={props.value}
          onChange={(e) => {
            props.onChange(e);
          }}
          placeholder={'MM/AA'}
          mask="99/99"
          name={props.name}
          onFocus={() => setLabel(true)}
          onBlur={() =>
            props.value === '  /  ' ? setLabel(false) : ''
          }
          overrides={{
            Root: {
              style: () => {
                return {
                  position: 'relative',
                  zIndex: '3',
                };
              },
            },
            InputContainer: {
              style: ({ $isFocused, $disabled, $error }) => {
                return {
                  backgroundColor: $disabled
                    ? 'transparent'
                    : 'white',
                  borderWidth: 0,
                  borderBottom: `2px solid ${
                    $isFocused
                      ? globalState.commerce.colorLogo !== undefined
                        ? '#' + globalState.commerce.colorLogo
                        : '#323232'
                      : $error
                      ? 'red'
                      : $disabled
                      ? 'transparent'
                      : '#e4e4e4'
                  }`,
                  height: '40px',
                };
              },
            },
            Before: props.icon
              ? () => (
                  <Before
                    disabled={props.disabled}
                    error={props.error}
                    customColor={props.customColor}
                    customIcon={props.customIcon}
                    icon={props.icon}
                  />
                )
              : null,
          }}
        />

        <Collapse isOpened={isString(props.error)} className={'ro'}>
          <small className={'error'}>{props.error}</small>
        </Collapse>
      </div>
    </ContInput>
  );
};

const InputPassword = (props) => {
  let After = props.iconAfter;
  let isPassword = true;
  const [labelUp, setLabel] = useState(false);
  const [globalState] = useGlobal();

  let ColorTema = '#' + globalState.commerce.colorLogo;

  useEffect(() => {
    if (props.value) {
      setLabel(true);
    }
  }, []);

  return (
    <ContInput
      labelUp={labelUp}
      leftSpace={
        (props.icon || props.customIcon) !== undefined ? '40px' : '0'
      }
      haslabel={props.label !== undefined}
      className={props.className}
      flex={props.flex}
      isPassword={isPassword}
    >
      <div className="wc position-relative">
        {props.label && <label>{props.label}</label>}
        <InputBase
          max={props.max}
          overrides={{
            InputContainer: {
              style: ({ $isFocused, $disabled, $error }) => {
                return {
                  borderWidth: '0',
                  height: '40px',
                  borderBottom: `2px solid ${ValidaColorBorde(
                    $isFocused,
                    $error,
                    ColorTema
                  )}`,
                  borderColor: ValidaColorBorde(
                    $isFocused,
                    $error,
                    ColorTema
                  ),
                  position: 'relative',
                  zIndex: '2',
                  marginBottom: '0',
                  backgroundColor: 'rgba(0,0,0,0)',
                  color: props.disabled
                    ? 'rgb(175, 175, 175)'
                    : 'black',
                  cursor: props.disabled ? 'not-allowed' : 'pointer',
                  paddingRight: 0,
                };
              },
            },
            Input: {
              style: () => {
                return {
                  paddingRight: '0',
                  backgroundColor: 'rgba(0,0,0,0)',
                  color: props.disabled
                    ? 'rgb(175, 175, 175)'
                    : 'black',
                  paddingLeft: '5px',
                };
              },
            },
            Before:
              props.icon || props.customIcon
                ? () => (
                    <Before
                      disabled={props.disabled}
                      error={props.error}
                      customColor={props.customColor}
                      customIcon={props.customIcon}
                      icon={props.icon}
                    />
                  )
                : null,

            //props.icon || props.customIcon ? Before : null,
            After: props.iconAfter ? After : null,
          }}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          name={props.name}
          inputRef={props.REF}
          onBlur={() => {
            if (props.value !== undefined) {
              if (props.value !== '' && props.value.length !== 0) {
                setLabel(true);
              } else {
                setLabel(false);
              }
            } else {
              setLabel(false);
            }
          }}
          onFocus={() => setLabel(true)}
          error={typeof props.error == 'string'}
          getValueLabel={({ option }) => option.value}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          pattern="[0-9]*"
          inputmode="numeric"
        />
        <ShowError error={props.error} />
      </div>
    </ContInput>
  );
};

const Input = (props) => {
  switch (props.tipo) {
    case 'text':
      return <InputTextNumber {...props} />;

    case 'select':
      return <SelectInput {...props} />;

    case 'date':
      return <DateInput {...props} />;

    case 'tdc':
      return <InputTDC {...props} />;

    case 'check':
      return <Check {...props} />;

    case 'password':
      return <InputPassword {...props} />;

    default:
      return <InputTextNumber {...props} />;
  }
};

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
};

Input.defaultProps = {
  type: 'text',
  placeholder: '',
};

export default React.memo(Input);
