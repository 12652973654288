import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import { useSpring } from 'react-spring';
import is from 'is_js';

/**
 * Queries
 */
import { TRANSACTION } from '../../data/queries/transaction';

/**
 * Components
 */
import Overlay from '../../components/overlay';
import Header from '../../components/header';
import Alert, { TYPE, VARIANT } from '../../components/alert';
import Step, { STEP } from '../../components/step';
import Wrappercheckout from '../../components/wrapperCheckout';
import Checkoutcontainer from '../../components/ckeckoutContainer';
import Mainbutton from '../../components/MainButton';
import Lockprocesing from '../../components/lockProcesing';
import Migas from '../../components/migas';
import Modalerror from '../../components/modalError';
import Flex from '../../components/Flex';

/**
 * Global state
 */
import useGlobal from '../../config/global';
import { animated } from 'react-spring';
import tinycolor2 from 'tinycolor2';
import { getResponseFinish } from '../../util/helpers/response';

export const closeCheckout = (data = null, action = false) => {
  if (window === window.top) {
    alert('Close only in iframe embedding');
  } else if (action) {
    window.parent.postMessage('closeActionCheckout', '*');
    window.parent.postMessage(
      {
        event: 'onFinishResponse',
        data: { ...data, type: 'isUpdate', value: action },
      },
      '*'
    );
  } else {
    window.parent.postMessage('closeActionCheckout', '*');
    window.parent.postMessage(
      {
        event: 'onFinishResponse',
        data,
      },
      '*'
    );
  }
};
export const closeCheckoutDelete = (data = null, action = false) => {
  if (window === window.top) {
    alert('Close only in iframe embedding');
  } else if (!action) {
    window.parent.postMessage('closeActionCheckout', '*');
    window.parent.postMessage(
      {
        event: 'onFinishResponse',
        data: { ...data, type: 'isDelete', value: action },
      },
      '*'
    );
  }
};

function OnePage() {
  // eslint-disable-next-line

  const [estado, setEstado] = useState({
    modal: false,
    secureBy: false,
    destroyIframe: false,
  });

  /**
   * Query
   */
  const [a, b] = useGlobal();

  const [globalState, globalActions] = useMemo(() => [a, b], [a, b]);

  const { id } = useParams();

  const { loading, error, data } = useQuery(TRANSACTION, {
    variables: { _id: id },
  });

  /**
   * Animation header
   */
  const HeaderProps = useSpring({
    config: { mass: 1, tension: 350, friction: 25 },
    from: {
      opacity: 0,
      transform: 'translateY(-100%)',
    },
    to: {
      opacity: estado.secureBy ? 1 : 0,
      transform: estado.secureBy
        ? 'translateY(0)'
        : 'translateY(-100%)',
    },
  });

  /**
   * Action update
   */
  const actulizaEstado = (x) =>
    setEstado(Object.assign({}, estado, x));

  let entroModal = useMemo(() => estado.modal, [estado.modal]);

  const [secureByProps, set, stop] = useSpring(() => ({
    opacity: 1,
  }));

  // Update spring with new props
  set({ opacity: estado.secureBy ? 1 : 0 });
  // Stop animation
  stop();

  /**
   * On load status and set
   */
  useEffect(() => {
    if (loading) actulizaEstado({ modal: true });
  }, [loading]);

  /**
   * Al cargar los datos de la transaccion
   */
  useEffect(() => {
    if (data) {
      const { commerce, transaction } = data['transaction'];

      let color = tinycolor2('#' + commerce.colorLogo);
      // let color  =  tinycolor2("#000");

      let light = new Number(color.getLuminance());

      console.log(light.toFixed(2));

      let newColor = color.toString();
      let colorLink = color.toString();

      if (light > 0 && light > 0.8) {
        let solido = (light - 0.8) * 100;

        newColor = color.darken(10).toString();
        colorLink = color.darken(solido + 10).toString();
        if (light > 0.85) {
          colorLink = '#4fc3f7';
        }
      } else {
        if (light < 0.05) {
          newColor = color.lighten(20).toString();
        }
      }

      commerce.colorLogo = newColor.replace('#', '');
      commerce.color = newColor.replace('#', '');
      commerce.colorLink = colorLink.replace('#', '');

      globalActions.setDataTransaction(transaction);
      globalActions.setDataCommerce(commerce);
      let isAdmin = false;

      if (
        transaction.hasOwnProperty('epaycoSubscription') &&
        transaction.epaycoSubscription !== null
      ) {
        //Validar si vienen los parametros de tipo domiciliación
        if (
          transaction.epaycoSubscription.hasOwnProperty(
            'suscriptionProjectId'
          ) &&
          transaction.epaycoSubscription.hasOwnProperty(
            'parameterSearch'
          )
        ) {
          if (transaction.epaycoSubscription.isAdmin) {
            isAdmin = true;
            globalActions.setIsAdmin(
              transaction.epaycoSubscription.isAdmin
            );
          }
          globalActions.setType('domiciliation');
          globalActions.setSuscriptionProjectId(
            transaction.epaycoSubscription.suscriptionProjectId
          );
          globalActions.setParameterSearch(
            transaction.epaycoSubscription.parameterSearch
          );
        }
        //Validar si viene la acción a mostrar en el detalle de la transacción
        const action = transaction.epaycoSubscription.accion;
        if (action) {
          if (action === 'suscribirPagar') {
            globalActions.setAction('Pagar y suscribir');
          }
        }
        if (isAdmin || transaction.epaycoSubscription.isLink) {
          globalActions.setAction('Agregar medio de pago');
        }
      } else if (
        transaction.hasOwnProperty('epaycoIsMultiple') &&
        transaction.epaycoIsMultiple === 'true'
      ) {
        globalActions.setType('domiciliation');
        globalActions.setIsMultipleSubscriptions(true);
        globalActions.setMultipleSubscriptions(
          transaction.epaycoMultipleSubscriptions
        );

        const subscriptionProjectsIds =
          transaction.epaycoMultipleSubscriptions.map(
            (item) => item.suscriptionProjectId
          );
        const recaudoProjectsIds =
          transaction.epaycoMultipleSubscriptions.map(
            (item) => item.recaudoProyectoId
          );
        globalActions.setMultipleSubscriptionsProjectsIds(
          subscriptionProjectsIds
        );
        globalActions.setMultipleRecaudoProjectsIds(
          recaudoProjectsIds
        );
      }
    }
  }, [data]);

  useEffect(() => {
    if (globalState.closeModalGlobal) {
      actulizaEstado({
        modal: false,
        destroyIframe: true,
      });
    }
  }, [globalState.closeModalGlobal]);

  /**
   * Render error
   */

  /**
   * Global data log
   */
  // if (process.env.NODE_ENV === 'development' && is.not.empty(data)) {
  //   console.group('Data transaction');
  //   console.log('commerce:', commerce);
  //   console.log('transaction:', transaction);
  //   console.groupEnd();
  // }

  if (error) {
    return console.log(error, 'ERROR EN LA CONSULTA :::::::::');
    // return (
    //   <Alert
    //     type={TYPE.static}
    //     variant={VARIANT.error}
    //     message={error}
    //   />
    // );
  }

  /**
   * Render loading
   */
  if (loading) {
    return (
      <Fragment>
        <Overlay background={'rgba(0,0,0,.1)'}>
          <Flex direction={'column'} className={'py-5'}>
            <h2 style={{ color: 'white' }}>loading...</h2>
          </Flex>
        </Overlay>
      </Fragment>
    );
  }
  const { commerce, transaction } = data['transaction'];

  return (
    <Fragment>
      <Overlay
        show={estado.modal}
        background={'rgba(0,0,0,.5)'}
        // in={!is.null(data['transaction'])}
      />

      <Wrappercheckout>
        {is.null(data['transaction']) ? (
          <Alert
            type={TYPE.static}
            variant={VARIANT.error}
            message="Transaction not found"
          />
        ) : (
          <Fragment>
            <Checkoutcontainer
              show={entroModal}
              onRest={() => {
                actulizaEstado({ secureBy: true });
                if (estado.destroyIframe) {
                  closeCheckout(
                    getResponseFinish(globalState),
                    globalState.isUpdate
                  );
                }
              }}
            >
              <Header
                commerce={commerce}
                transaction={transaction}
                style={HeaderProps}
                close={() =>
                  actulizaEstado({
                    modal: false,
                    destroyIframe: true,
                  })
                }
              />

              <Migas />

              {estado.secureBy && (
                <Step
                  transaction={transaction}
                  closeCheckout={() =>
                    actulizaEstado({
                      modal: false,
                      destroyIframe: true,
                    })
                  }
                />
              )}

              <Mainbutton />
              <Modalerror />
            </Checkoutcontainer>

            <animated.div
              className={
                'wc text-center pt-3 pb-2 d-none d-md-flex justify-content-center align-items-center'
              }
              style={{
                color: 'white',
                ...secureByProps,
                flex: '0 0 auto',
                maxWidth: '400px',
              }}
            >
              <Lockprocesing loading={globalState.loading} />
              <span style={{ fontSize: '17px' }}>Secured by</span>
              <img
                src="/img/epayco_b.svg"
                alt=""
                height={'22px'}
                width={'auto'}
                className={'ml-2'}
              />
            </animated.div>
          </Fragment>
        )}
      </Wrappercheckout>
    </Fragment>
  );
}

export default React.memo(OnePage);
