import React, { useState, useEffect, useRef } from 'react';
import Input from '../input';
import { useMutation } from '@apollo/react-hooks';

/**
 * Styles
 */
import { Container } from './styles';

/**
 * Components
 */
import { STEP } from '../step';
import Loading from '../loading';

/**
 * Global
 */
import useGlobal from '../../config/global';

/**
 * Mutation
 */
import {
  AUTH_EMAIL,
  EMAIL_VERIFICATION,
  VALIDATE_UNIQUE_PROJECT_EMAIL,
  VALIDATE_UNIQUE_PROJECT_REFERENCE,
} from '../../data/mutations/email';

function Validatecode({ Ref }) {
  const [state, setState] = useState({
    code: '',
    processing: false,
    error: '',
    autofocus: false,
    entro: false,
    dataSuscription: {},
  });

  const [globalState, globalActions] = useGlobal();
  let validateCodeRef = useRef(null);

  const actualizaEstado = (x) =>
    setState(Object.assign({}, state, x));

  const [validateUniqueProjectReference] = useMutation(
    VALIDATE_UNIQUE_PROJECT_REFERENCE,
    {
      onError: (error) => {
        globalActions.setErrorAlert({
          icon: 'error',
          title: 'Ocurrió un error validando la referencia',
          message: ['Ocurrió un error validando la referencia'],
        });
        globalActions.setStep(STEP.login);
      },
      onCompleted: (data) => {
        actualizaEstado({ processing: false });
        let { status, msg } = data.validateUniqueProjectReference;
        if (status) {
          globalActions.setStep(STEP.methods);
        } else {
          globalActions.setErrorAlert({
            icon: 'error',
            title: msg,
            message: [''],
          });
          globalActions.setStep(STEP.login);
        }
      },
    }
  );

  const [validateUniqueProjectEmail] = useMutation(
    VALIDATE_UNIQUE_PROJECT_EMAIL,
    {
      onError: (error) => {
        globalActions.setErrorAlert({
          icon: 'error',
          title: 'Ocurrió un error',
          message: ['Ocurrió un error al consultar'],
        });
        globalActions.setStep(STEP.login);
      },
      onCompleted: (data) => {
        let { status, customer, msg } =
          data.validateUniqueProjectEmail;

        if (status) {
          const { epaycoSubscription } = globalState.transaction;

          if (
            epaycoSubscription &&
            epaycoSubscription.suscriptionProjectId &&
            (epaycoSubscription.isAdmin || epaycoSubscription.isLink)
          ) {
            // globalActions.setDataSubscriptionDomicilation(state.dataSuscription);

            globalActions.setStep(STEP.admin);
            globalActions.setCustomerMongoId(
              customer.customer_mongo_id
            );
            globalActions.setPoseeSuscripcion(true);
          } else {
            if (customer) {
              let { usuario } = customer;
              let nombre = usuario.split(' ')[0];
              let apellido = usuario.split(' ');
              apellido = apellido[apellido.length - 1];

              let dataCustomerActive = {
                name: nombre,
                lastName: apellido,
                indicative: customer.indicativo,
                phone: customer.celular,
                documentType: customer.tipo_identificacion,
                documentNumber: customer.numero_identificacion,
                country: customer.nombre_corto,
                city: customer.departamento,
                address: customer.direccion,
              };

              globalActions.updateState({ dataCustomerActive });
              globalActions.setPoseeSuscripcion(true);
              globalActions.setCustomerMongoId(
                customer.customer_mongo_id
              );
            }

            if (globalState.isMultipleSubscriptions) {
              globalActions.setStep(STEP.methods);
            } else {
              const parameters = JSON.parse(
                epaycoSubscription.parameterSearch
              );
              validateUniqueProjectReference({
                variables: {
                  key: `${parameters.identificador}`,
                  subscriptionProjectId:
                    epaycoSubscription.suscriptionProjectId,
                  idTransaction: globalState.idTransaction,
                },
              });
            }
          }
        } else {
          globalActions.setErrorAlert({
            message: [msg],
            icon: 'error',
            title: 'Ocurrió un error',
          });
          globalActions.setStep(STEP.login);
        }
      },
    }
  );

  const [authEmail] = useMutation(AUTH_EMAIL, {
    onError: (error) => {
      if (error.message.includes('be equal in domiciliation email')) {
        actualizaEstado({
          processing: false,
          error: `El email ${globalState.email} no coincide con el registrado en la domiciliación.`,
        });
      }
    },
    onCompleted: (data) => {
      globalActions.setSession({ email: globalState.email });
      globalActions.setMethods(data.authEmail.paymentMethods.methods);
      globalActions.setCards(data.authEmail.cards.oneClick);
      globalActions.setCardsSubscription(
        data.authEmail.cards.subscription
      );
      globalActions.setDataSubscription(data.authEmail.subscription);

      //go paymet or admin subscription
      const {
        authEmail: { subscription },
      } = data;
      const { epaycoSubscription } = globalState.transaction;

      //actualizaEstado({ dataSuscription: subscription });
      globalActions.setDataSubscriptionDomicilation(subscription);

      setState({ ...state, dataSuscription: subscription });
      validateUniqueProjectEmail({
        variables: {
          email: globalState.email,
          suscriptionProjectId: globalState.isMultipleSubscriptions
            ? globalState.multipleSubscriptionsProjectsIds[0]
            : epaycoSubscription.suscriptionProjectId,
          idTransaction: globalState.idTransaction,
        },
      });
    },
  });

  const [verificationCode] = useMutation(EMAIL_VERIFICATION, {
    onCompleted: (response) => {
      if (!response.emailVerification.status) {
        actualizaEstado({
          processing: false,
          error: response.emailVerification.message,
        });
      } else {
        authEmail({
          variables: {
            email: globalState.email,
            type: globalState.type,
            idTransaction: globalState.idTransaction,
            idCustom: globalState.transaction.epaycoSubscription
              ? globalState.transaction.epaycoSubscription.custom_id
                ? globalState.transaction.epaycoSubscription.custom_id
                : null
              : null,
            idPlan: globalState.transaction.epaycoSubscription
              ? globalState.transaction.epaycoSubscription.id_plan
                ? globalState.transaction.epaycoSubscription.id_plan
                : null
              : null,
          },
        });
      }
    },
  });

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false; // Limpieza
    };
  }, []);

  useEffect(() => {
    if (globalState.isAnimating && isMounted.current) {
      setState({ ...state, entro: true });
    }
    if (
      state.entro &&
      !globalState.isAnimating &&
      isMounted.current
    ) {
      validateCodeRef.current.focus();
    }
  }, [globalState]);
  useEffect(() => {
    if (globalState.transaction.epaycoCustomer) {
      actualizaEstado({ processing: true });
      authEmail({
        variables: {
          email: globalState.email,
          type: globalState.type,
          idTransaction: globalState.idTransaction,
          idCustom: globalState.transaction.epaycoSubscription
            ? globalState.transaction.epaycoSubscription.custom_id
              ? globalState.transaction.epaycoSubscription.custom_id
              : null
            : null,
          idPlan: globalState.transaction.epaycoSubscription
            ? globalState.transaction.epaycoSubscription.id_plan
              ? globalState.transaction.epaycoSubscription.id_plan
              : null
            : null,
        },
      });
    }
  }, []);

  let colorT =
    globalState.commerce.colorLink !== undefined
      ? '#' + globalState.commerce.colorLink
      : '#323232';

  return (
    <Container
      className="validateCode p-4"
      direction={'column'}
      jc={'flex-start'}
      color={colorT}
    >
      <div className="wc pt-5 text-center">
        <img
          src={
            process.env.AWS_MULTIMEDIA_S3 +
            '/dashboard/modal_icons/warning.png'
          }
          alt=""
          height={'70px'}
          width={'auto'}
          className={'mx-auto mb-3'}
        />
        {globalState.transaction.epaycoCustomer ? (
          <p>Cargando...</p>
        ) : (
          <p>
            Ingrese el código de confirmación que hemos enviado a su
            correo para continuar con el proceso
          </p>
        )}
      </div>
      <form
        className={'wc'}
        ref={Ref}
        onSubmit={(e) => {
          e.preventDefault();
          validateCodeRef.current.blur();

          if (state.code === '' || state.code.length < 6) {
            actualizaEstado({
              error: 'Código de confirmación no válido.',
              processing: false,
            });
          } else {
            actualizaEstado({ processing: true });
            verificationCode({
              variables: {
                code: state.code,
                idTransaction: globalState.idTransaction,
              },
            });
          }
        }}
      >
        {!globalState.transaction.epaycoCustomer && (
          <Input
            icon={'lock'}
            type={'number'}
            value={state.code}
            onChange={(e) => {
              actualizaEstado({
                code: e.target.value,
                error: '',
              });
            }}
            error={state.error !== '' && state.error}
            disabled={state.processing}
            autoFocus={state.autofocus}
            REF={validateCodeRef}
          />
        )}
      </form>
      <div className="wc pt-3">
        <Loading show={state.processing} />
      </div>
      {!state.processing &&
        !globalState.transaction.epaycoCustomer && (
          <div className="wc text-center pt-4">
            <span
              className={'back'}
              onClick={() => {
                globalActions.setDirection(true);
                globalActions.setStep(STEP.login);
              }}
            >
              <i
                className={'material-icons'}
                style={{
                  lineHeight: '14px',
                  height: '18px',
                  verticalAlign: 'bottom',
                }}
              >
                keyboard_arrow_left
              </i>
              Cambiar correo
            </span>
          </div>
        )}
    </Container>
  );
}

Validatecode.propTypes = {};

Validatecode.defaultProps = {};

export default React.memo(Validatecode);
