import React from 'react';
import globalHook from 'use-global-hook';
import is from 'is_js';

const initialState = {
  isAdmin: false,
  token: null,
  cardsDomiciliation: [],
  responseInfo: [],
  action: 'Suscribir',
  idTransaction: null,
  isSession: false,
  email: null,
  step: 'login',
  dataForm: {},
  type: 'subscription',
  parameterSearch: null,
  suscriptionProjectId: null,
  dataResponse: {},
  dataSubscription: {},
  transaction: {},
  commerce: {
    colorLogo: '3a3a3a',
  },
  isAnimating: false,
  loading: false,
  errorAlert: {
    title: '',
    icon: '',
    message: [],
    content: false,
    btns: [],
    hideButtons: false,
    top: null,
  },
  closeCheckout: '',
  methods: [],
  cards: [],
  cardsSubscription: [],
  subscription: {},
  lockDrag: false,
  mainButton: {
    show: false,
    text: false,
    onClick: false,
  },
  newPhone: {
    indicative: {
      label: 'colombia',
      value: '+57',
    },
    number: '',
    error: false,
  },
  migas: [],
  migasUp: {
    show: false,
    icon: true,
    iconName: '',
  },
  leftDirection: false,
  poseeSuscripcion: false,
  customerMongoId: '',
  cardsWithsuscriptions: [],
  cardsSelectedForsuscribe: [],
  dataCustomerActive: {},
  closeModalGlobal: false,
  isUpdate: false,
  isMultipleSubscriptions: false,
  multipleSubscriptions: [],
  multipleSubscriptionsProjectsIds: [],
  multipleRecaudoProjectsIds: [],
};

const actions = {
  setIdTransaction: (store, idTransaction) => {
    store.setState({
      idTransaction,
    });
  },
  setSession: (store, { email }) => {
    store.setState({
      isSession: {
        email,
      },
    });
  },
  setType: (store, type) => {
    store.setState({
      type,
    });
  },
  setToken: (store, token) => {
    store.setState({
      token,
    });
  },
  setCardsDomiciliation: (store, cards) => {
    store.setState({
      cardsDomiciliation: cards,
    });
  },
  setAction: (store, action) => {
    store.setState({
      action,
    });
  },
  setResponseInfo: (store, responseInfo) => {
    store.setState({
      responseInfo,
    });
  },
  setIsAdmin: (store, value) => {
    store.setState({
      isAdmin: value,
    });
  },
  setEmail: (store, email) => {
    store.setState({
      email,
    });
  },
  setMethods: (store, methods) => {
    store.setState({
      methods,
    });
  },
  setCards: (store, cards) => {
    store.setState({
      cards,
    });
  },
  setCardsSubscription: (store, cardsSubscription) => {
    store.setState({ cardsSubscription });
  },
  setSuscriptionProjectId: (store, suscriptionProjectId) => {
    store.setState({
      suscriptionProjectId,
    });
  },
  setParameterSearch: (store, parameterSearch) => {
    store.setState({
      parameterSearch,
    });
  },
  closeSession: (store, value) => {
    store.setState({
      isSession: false,
      step: initialState.step,
    });
  },
  setStep: (store, value) => {
    store.setState({
      step: is.not.empty(value) ? value : initialState.step,
    });
  },
  setDataForm: (store, value) => {
    store.setState({
      dataForm: is.not.empty(value) ? value : {},
    });
  },
  setDataResponse: (store, value) => {
    store.setState({
      dataResponse: is.not.empty(value) ? value : {},
    });
  },
  setDataSubscriptionDomicilation: (store, value) => {
    store.setState({
      dataSubscription: is.not.empty(value) ? value : {},
    });
  },
  setDataTransaction: (store, value) => {
    store.setState({
      transaction: is.not.empty(value) ? value : {},
    });
  },
  setDataCommerce: (store, value) => {
    store.setState({
      commerce: is.not.empty(value) ? value : {},
    });
  },
  setAnimating: (store, value) => {
    store.setState({
      isAnimating: is.not.empty(value) ? value : false,
    });
  },
  setLoading: (store, value) => {
    store.setState({
      loading: is.not.empty(value) ? value : false,
    });
  },
  lockDrag: (store, value) => {
    store.setState({
      lockDrag: is.not.empty(value) ? value : false,
    });
  },
  setErrorAlert: (store, value) => {
    store.setState({
      errorAlert: is.not.empty(value)
        ? Object.assign({}, initialState.errorAlert, value)
        : { message: false, content: false, btns: [] },
    });
  },
  setCloseCheckout: (store, value) => {
    store.setState({
      closeCheckout: is.not.empty(value) ? value : false,
    });
  },
  setDataSubscription: (store, value) => {
    store.setState({
      subscription: is.not.empty(value) ? value : {},
    });
  },

  setMainButton: (store, value) => {
    store.setState({
      mainButton: is.not.empty(value)
        ? { ...initialState.mainButton, ...value }
        : {},
    });
  },
  setNewPhone: (store, value) => {
    store.setState({
      newPhone: is.not.empty(value)
        ? Object.assign({}, store.newPhone, value)
        : {},
    });
  },
  setMiga: (store, value) => {
    store.setState({
      migas: is.not.empty(value) ? value : [],
    });
  },
  setDirection: (store, value) => {
    store.setState({
      leftDirection: is.not.empty(value) ? value : false,
    });
  },
  setPoseeSuscripcion: (store, value) => {
    store.setState({
      poseeSuscripcion: is.not.empty(value) ? value : false,
    });
  },
  setCustomerMongoId: (store, value) => {
    store.setState({
      customerMongoId: is.not.empty(value) ? value : '',
    });
  },
  setCardsWithsuscriptions: (store, value) => {
    store.setState({
      cardsWithsuscriptions: is.not.empty(value) ? value : [],
    });
  },
  setIsUpdate: (store, value) => {
    store.setState({
      isUpdate: value,
    });
  },

  updateState: (store, value) => {
    store.setState({
      ...value,
    });
  },

  setIsMultipleSubscriptions: (store, value) => {
    store.setState({
      isMultipleSubscriptions: value,
    });
  },

  setMultipleSubscriptions: (store, value) => {
    store.setState({
      multipleSubscriptions: value,
    });
  },

  setMultipleSubscriptionsProjectsIds: (store, value) => {
    store.setState({
      multipleSubscriptionsProjectsIds: value,
    });
  },

  setMultipleRecaudoProjectsIds: (store, value) => {
    store.setState({
      multipleRecaudoProjectsIds: value,
    });
  },
};

export default globalHook(initialState, actions);
